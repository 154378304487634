import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import FurtherReading from "../components/furtherReading"

const SevenVitamins = () => (
  <Layout>
    <SEO
      title="7 vitamins and minerals for growing healthy afro hair"
      description="We need vitamins to grow strong healthy hair but which ones are they? Here's the complete list and exactly where you can find them."
    />
    <h1>7 vitamins and minerals for growing healthy afro hair</h1>
    <h2 style={{color:"Orchid"}}>We need vitamins to grow strong healthy hair but which ones are they? Here's the complete list and exactly where you can find them</h2>

    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/xuKcHDb.jpg"
        alt="natural afro hair care 7 vitamins and minerals for growing healthy afro hair"
        style={{marginBottom: "5px"}}
      />
      <p style={{fontSize: "0.9rem", textAlign:"left", color:"grey"}}>Photo credit: <a
        href="https://unsplash.com/@danielcgold"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration:"none", color:"inherit" }}
      >Dan Gold</a></p>
    </div>

    {/* <h2>Subtitle</h2> */}

    {/* <a
        href="https://example.com/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        Example Link - for affiliate, add nofollow
      </a> */}

      <p>Getting proper nutrition is one of the best ways to not only get beautiful hair but also live a healthy and comfortable life. Most of the vitamins we need are found in a balanced diet. "Balanced" being the key word. For one reason or another, some of us might be missing or not getting enough of the essential vitamins that we need. It could be that you don't have it in your diet or your body isn't able to process it properly when you do get it.</p>
      <p>It's not just hair that suffers from lack of proper nutrition. Your skin could be dry, patchy and not behaving like it's usual self. Your nails could lack shine, break more easily than usual and not grow as fast as they used to. You might even feel a constant mental fog and general lack of energy.</p>
      <p>Some fixes are easier than others and I won't promise to solve all of your problems. If you have a more serious condition, you might have to see a doctor. If you have done a bit of research and truly believe vitamins could be the solution to your problem then read on.</p>
      <p>We'll start by going over the vitamins and nutrients that are good for hair health and growth. At the same time we'll go into where you can find them naturally then look at which multivitamins and supplements you can use to make things easier. So whether you describe your hair as black, afro, kinky, African, African American, curly, coily, wavy and all the rest, this guide is for you.</p>

      <h2>Vitamins for healthy hair</h2>
      <p>I didn't follow a standard format for each vitamin or nutrient below but I tried to cover the following points;</p>
      <ul>
          <li>What it does for your body</li>
          <li>What it does for you hair</li>
          <li>Which foods you can find it in</li>
          <li>Problems that come from a deficiency</li>
      </ul>
      <p>It should help give you an understanding of why each one is important for growing afro hair or any hair type for that matter. By the end, you should leave this page knowing more about this topic than you do now.</p>
      <p>See list of references/ bibliography at the end.</p>
      <p style={{fontSize: "0.9rem", textAlign:"left", color:"grey"}}><i>Some of the links in this article may be affiliate links, which means I may receive a small commission at no additional cost to you if you decide to purchase something.</i></p>

      <h3>Vitamin A</h3>
      <p>Vitamin A is required for all cell growth. It also helps the skin glands create sebum This is the natural oily, waxy substance we produce that helps moisturise our scalp. It helps keep our hair healthy. It's pretty hard to overdose but getting too much Vitamin A can oddly cause problems that lead to hair loss so it's important to maintain a good balance.</p>
      <p>Vitamin A can be found in Cod liver oil. Eggs, fortified skimmed milk and cereals. Many fruits such as avocado, mango, apricots and peaches. Beta-carotene is broken down in the body to form vitamin A so a few other great sources are broccoli, spinach carrots and sweet potato.</p>
      <p><a
        href="https://www.amazon.com/NOW-Supplements-Vitamin-Liver-Softgels/dp/B001B4P0I6?dchild=1&keywords=vitamin+a&qid=1616348701&sr=8-1&linkCode=ll1&tag=natural032d-20&linkId=3590d35a31f197c8f4d10598ae215ae2&language=en_US&ref_=as_li_ss_tl"
        target="_blank"
        rel="noopener noreferrer nofollow"
        style={{ textDecoration: "none" }}
      >
        Shop Vitamin A
      </a></p>

      <h3>B-Vitamins</h3>
      <p>There are 8 essential nutrients in the group of B vitamins, together called vitamin B complex. To name a few; Thiamine(B-1) - necessary for producing fatty acids. Niacin (B-3) - changing energy in carbs, fats and proteins into something the body can absorb and use. Biotin(B-7) - breaking down fats etc. for absorption like niacin. Folate (B-9) - needed for replication of DNA, breaking down of vitamins and amino acids, also required for cell division. Vitamin B-12,  necessary for the creation of red blood cells. Also for the absorption of fat and protein.</p>
      <p>In short, B vitamins work together to help create red blood cells. These carry oxygen around the body. In our interest to the scalp and hair follicles which in turn promote healthy hair growth.</p>
      <p>Most of these vitamins occur together in various foods so it isn't usually necessary to search for specific types. They are more abundant in animal products which is why it's more likely to have a deficiency if you are a vegetarian or vegan. Beef, salmon, milk and yoghurt are all good sources of B vitamins. You can also get them from whole grains, almonds and dark, leafy greens.</p>
      <p><a
        href="https://www.amazon.com/Natures-Bounty-Supplement-Metabolism-Antioxidant/dp/B0014D2GEK?dchild=1&keywords=vitamin+b&qid=1616348735&sr=8-1&linkCode=ll1&tag=natural032d-20&linkId=308fc6de6b1de3a0d1281f50473993fc&language=en_US&ref_=as_li_ss_tl"
        target="_blank"
        rel="noopener noreferrer nofollow"
        style={{ textDecoration: "none" }}
      >
        Shop Vitamin B
      </a></p>

      <h3>Vitamin C</h3>
      <p>Vitamin C is needed for the body to absorb iron which is important for hair growth as we'll learn about soon. It is important for helping the body repair damaged cells and tissue. Vitamin C is an antioxidant giving it more healing and repairing properties. Oxidants are thought to accelerate the process of aging and skin damage from external factors. Antioxidants like the ones found in vitamin C help fight this from happening prematurely.</p>
      <p>One of the forms of vitamin C is ascorbic acid and as you can tell by the name it is not ph balanced. It is an acid and applied to the skin directly it can cause irritation. This means you shouldn't apply it to your scalp or hair by itself. To get the benefits, you should consume it or mix it with something else to balance the ph.</p>
      <p>A dry scalp or dandruff has many causes. One of them could be that your immune system is not functioning properly and this imbalance is causing you to produce the dandruff. Vitamin C is an immune system booster and could help you fight the issue.</p>
      <p>Vitamin C can be found in many citrus fruits like lemon and orange and can also be found in many green vegetables as well as tomatoes. A quick note is that Vitamin C is water soluble meaning it is easily lost from the fruit and vegetable. If you let it over ripen, cook it too much or over-boil it the vitamin will be lost. Fresh is best.</p>
      <p>Given modern diets, it's highly unlikely that you will be deficient in vitamin C but if you are you could be experiencing dandruff (as mentioned above), brittle hair and hair loss.</p>
      <p><a
        href="https://www.amazon.com/Natures-Bounty-Vitamin-1000-Caplets/dp/B0019GE9HE?dchild=1&keywords=vitamin+c&qid=1616348761&sr=8-3&linkCode=ll1&tag=natural032d-20&linkId=dcfe3a966a95247353e22439d094b5e3&language=en_US&ref_=as_li_ss_tl"
        target="_blank"
        rel="noopener noreferrer nofollow"
        style={{ textDecoration: "none" }}
      >
        Shop Vitamin C
      </a></p>

      <h3>Vitamin D</h3>
      <p>Vitamin D is essential for all health and well-being in general. It stimulates cell growth which includes skin and the thing we are concerned about - hair.</p>
      <p>As you might know already, vitamin D is one you get from the sun. It can be found in supplements but wherever you live around the world, it's likely that you will get enough just by being exposed to the sun frequently.</p>
      <p>Another place you can get Vitamin D is in food. Here are some of the best sources; Fatty fish, some dairy products, cereals and egg yolk.</p>
      <p>If you somehow don't manage to get enough Vitamin D, the deficiency can manifest in a few different ways. Hair loss and bald spots are two. Before this you might notice your isn't growing as quickly or seemingly not at all.</p>
      <p>The vitamin helps regulate calcium and maintains phosphorus in the blood. It allows the intestines to absorb the calcium that would otherwise be excreted by the kidneys.  Therefore, sustained lack of vitamin D can lead to low bone density making them far easier to break or fracture. There are other more serious conditions like heart disease.</p>
      <p><a
        href="https://www.amazon.com/NatureWise-Vitamin-Function-Cold-Pressed-Gluten-Free/dp/B00GB85JR4?dchild=1&keywords=vitamin+d&qid=1616348788&sr=8-3&linkCode=ll1&tag=natural032d-20&linkId=1b24fa73abee458f6a6711d348f5b0fd&language=en_US&ref_=as_li_ss_tl"
        target="_blank"
        rel="noopener noreferrer nofollow"
        style={{ textDecoration: "none" }}
      >
        Shop Vitamin D
      </a></p>

      <h3>Vitamin E</h3>
      <p>Vitamin E is essential to maintain healthy skin and eyes as well as the immune system. It is an antioxidant which, as we discussed earlier, fights free radical damage. Like oxidation, free radical damage causes premature aging and cell damage. Antioxidants like vitamin E slow this natural process.</p>
      <p>The antioxidant properties of vitamin E make it a great tool for reducing the stress on our hair and scalp, including the follicles. This lessens damage caused naturally and by factors like drinking and smoking. It leads to increased chance of growing strong healthy hair.</p>
      <p>Studies have shown that oils containing vitamin E add shine when applied to the hair directly. When ingested vitamin E has been linked to the prevention of hair loss, improved circulation of blood to the scalp to promote hair growth, maintaining healthy oil levels on the scalp and improved scalp health in general.</p>
      <p>Like most of the vitamins on this list it is something you are probably not lacking in if you have a balanced diet. The vitamin can be found in many foods and has a higher concentration in plant oils like sunflower and soybean oil. Various nuts and seeds as well. It can also be found in wheat germ and cereals.</p>
      <p>A lack in Vitamin E can present itself in the following ways; retina damage that impairs vision. Nerve damage in the hands and feet that can cause pain. A Lowered immune system.</p>
      <p><a
        href="https://www.amazon.com/Natures-Bounty%C2%AE-Vitamin-Topical-ounces/dp/B0061GINBA?dchild=1&keywords=vitamin+e&qid=1616348808&sr=8-3&linkCode=ll1&tag=natural032d-20&linkId=79d6fd4a215f0ab25ee1d81fe6838a5b&language=en_US&ref_=as_li_ss_tl"
        target="_blank"
        rel="noopener noreferrer nofollow"
        style={{ textDecoration: "none" }}
      >
        Shop Vitamin E
      </a></p>

      <h2>Minerals</h2>
      <h3>Iron</h3>
      <p>Iron is what the body needs to make the protein in red blood cells that carries oxygen around the body called haemoglobin. It's also used to make myoglobin which is what carries oxygen to the muscles. Iron is also necessary in the creation of many hormones.</p>
      <p>It is important for hair growth and health because the scalp needs the oxygen carried by the haemoglobin to function. The follicles are stimulated and hair can grow because the follicles are functioning properly.</p>
      <p>A lack of iron leads to iron deficiency anaemia, it causes a noticeable lack of energy. You will be tired and have less stamina to do regular tasks. A deficiency can also cause hair loss because enough oxygen isn't being carried to the cells that are required for hair growth and repair.</p>
      <p>Females have a much higher chance of suffering from a lack of iron so it's important to check your levels if you suspect something is wrong and make changes. A nurse can take a blood sample and do a red blood cell count.</p>
      <p>Sources of food with a higher concentration of iron are red meat, liver and certain beans like kidney & chickpeas. Also iron fortified cereals, nuts, spinach and broccoli.</p>
      <p><a
        href="https://www.amazon.com/Vitron-C-Potency-Supplement-Vitamin-Count/dp/B00140Z5DW?dchild=1&keywords=iron+tablets&qid=1616348841&sr=8-4&linkCode=ll1&tag=natural032d-20&linkId=d1cf556fc7ae3f00eb02c1823cd44126&language=en_US&ref_=as_li_ss_tl"
        target="_blank"
        rel="noopener noreferrer nofollow"
        style={{ textDecoration: "none" }}
      >
        Shop Iron
      </a></p>

      <h3>Zinc</h3>
      <p>Zinc is an essential mineral that is required in the body for various functions. Those include maintaining the immune system, breaking down & using protein and the repairing of wounds.</p>
      <p>The mineral is important for our hair because of its tissue growth and repair properties. It helps the hair follicles function by ensuring the surrounding oil glands stay healthy. Zinc deficiency can lead to hair loss both at the scalp and through breakage.</p>
      <p>In the body, a lack of zinc causes a lowered sense of smell and taste, loss of appetite, weight loss, mental fog and wounds take longer to heal. A deficiency negatively affects cell production and your immune system. Zinc levels can be checked by your nurse or doctor by doing a blood test, urine test or hair sample analysis.</p>
      <p>Something to note about Zinc is that the body has no way of storing it making it one of the vitamins that you need to consume daily to remain healthy. Luckily it is found in many foods so most people have no problem getting their recommended or adequate intake.</p>
      <p>Some food sources of Zinc are baked beans, pork, beef, zinc fortified cereals, pumpkin seeds & yoghurt. Oysters have an unusually high amount of Zinc so if you eat these you probably never have to worry about your zinc intake.</p>
      <p><a
        href="https://www.amazon.com/Solgar-Zinc-50-100-Tablets/dp/B000A6LUC6?dchild=1&keywords=zinc+tablets&qid=1616348921&sr=8-3&linkCode=ll1&tag=natural032d-20&linkId=97bda4ec2e02a3c8a0ca2d3c8effcd7f&language=en_US&ref_=as_li_ss_tl"
        target="_blank"
        rel="noopener noreferrer nofollow"
        style={{ textDecoration: "none" }}
      >
        Shop Zinc
      </a></p>

      <h2>Multivitamins and Supplements</h2>
      <p>Luckily, like I've mentioned throughout, most of us are lucky and don't have to seek specific types of nutrition because we have a balanced diet and get what we need from food. In some cases and for whatever reason, we might be lacking in something. If you've read the list above and have done some research then found that you want to try multivitamins or supplements then here is a quick list of some you can get on Amazon.</p>
      <ul>
        <li><a
        href="https://www.amazon.com/Centrum-Multivitamin-Multimineral-Supplement-Vitamin/dp/B072PVP4CJ?dchild=1&keywords=multivitamins+for+women&qid=1616348387&sr=8-3&linkCode=ll1&tag=natural032d-20&linkId=54a8c785730cece2a261cbdaa47717ab&language=en_US&ref_=as_li_ss_tl"
        target="_blank"
        rel="noopener noreferrer nofollow"
        style={{ textDecoration: "none" }}
      >
      Multivitamins for women
      </a></li>
        <li><a
        href="https://www.amazon.com/Centrum-Multivitamin-Multimineral-Supplement-Antioxidants/dp/B00HLK0KAW?dchild=1&keywords=multivitamins+for+men&qid=1616348439&sr=8-4&linkCode=ll1&tag=natural032d-20&linkId=f24b50ea1a33ef9629da374d8529632e&language=en_US&ref_=as_li_ss_tl"
        target="_blank"
        rel="noopener noreferrer nofollow"
        style={{ textDecoration: "none" }}
      >
      Multivitamins for men
      </a></li>
        <li><a
        href="https://www.amazon.com/SmartyPants-Complete-Daily-Gummy-Vitamins/dp/B004QQ9LVS?dchild=1&keywords=multivitamins+for+children&qid=1616348476&sr=8-2&linkCode=ll1&tag=natural032d-20&linkId=52cbf40df0a062dea0a0b73482ae51c4&language=en_US&ref_=as_li_ss_tl"
        target="_blank"
        rel="noopener noreferrer nofollow"
        style={{ textDecoration: "none" }}
      >
      Multivitamins for children
      </a></li>
        <li><a
        href="https://www.amazon.com/Biotin-Infused-Organic-Virgin-Coconut/dp/B00IOZWC2M?dchild=1&keywords=vitamins+for+hair&qid=1616348327&sr=8-5&linkCode=ll1&tag=natural032d-20&linkId=3a4519fc403643e6461d8341f509d130&language=en_US&ref_=as_li_ss_tl"
        target="_blank"
        rel="noopener noreferrer nofollow"
        style={{ textDecoration: "none" }}
      >
      Supplements For hair, skin and nails
      </a></li>
      </ul>

      <p>Supplements are a good way of adding something that is missing but they are no replacement for a balanced diet. Try to think long term when making dietary decisions and make sure you know what you are doing. A good reason for taking supplements is that you suspect you are deficient in something and want to try it short term to see if it works. Then find a way to add it to your diet when you've hopefully run your successful test and live healthy.</p>

      <h2>Tips for taking supplements</h2>
      <h3>Check the nutrition list</h3>
      <p>Make sure you read the list on the label. It will tell you exactly what you can find in the supplement. If your goal is to get iron and zinc, check the list and make sure they are in there. I've had multivitamins from two different brands before and one contained all the nutrients from the other and a whole lot more.</p>
      <p>Saying that, more isn't always better if more isn't what you want. Make a list of the things you are looking for and make sure the vitamins are in there before you buy.</p>

      <h3>Check the RDA %</h3>
      <p>Following on from checking the nutrition list. Check the recommended daily allowance or RDA as a percentage. It will tell you how much of the nutrient you should be getting and how much they contain exactly.</p>
      <p>When you read the list, you will find some as low as 2% meaning you have to either make sure you get that thing from your diet or another supplement. It's also common to see some that go well over 100%, which brings me to the next tip.</p>

      <h3>Cut them in half</h3>
      <p>If you believe you are getting enough of the other nutrients in the list and your main nutrient is over 100% then cut the tablet in half. You should still see benefits, at the same time you will save some money on buying more because they will last twice as long.</p>

      <h3>Chewables</h3>
      <p>I know lots of us struggle to take pills even with a drink, on the other hand there are people that can swallow them down with no assistance. Anyway, if you do struggle there are some alternatives like chewables or gummies, they are like soft sweets and some even taste that way. You can also look out for pills that dissolve in water so you can drink your nutrients.</p>

      <h2>Conclusion</h2>
      <p>Vitamins and minerals are two of the things that keep us healthy and functioning as normal. As you've seen above, there are many and each has its own function so it's important to get the recommended allowance.</p>
      <p>If you have a condition and notice something is wrong, it could well be that you are lacking in some kind of nutrition. It might well be something worse so it's definitely worth seeing a doctor. If you find that it is due to a form of deficiency then I hope this list can help you learn about some of the jobs specific vitamins and minerals actually do.</p>
      <p>For the hair specifically, proper nutrition helps solve problems such as hair loss and thinning. It helps with scalp health, whether that's a build-up of excess oils or a dry scalp leading to dandruff and other issues. Good nutrition also promotes the growth of healthy hair that is stronger and can grow longer without breaking prematurely.</p>

      <p style={{fontSize: "0.9rem", textAlign:"left", color:"grey"}}><i>As an Amazon Associate I earn from qualifying purchases. Some of the links in this article may be affiliate links, which means I may receive a small commission at no additional cost to you if you decide to purchase something.</i></p>

      <h2>Bibliography</h2>
      <p>I am not a nutritionist or doctor and have no expertise in this field so I had to do a lot of research. While I can speak on my own experiences with nutrition, they are just that; my own experiences. Some of us are lactose intolerant, vegetarians, vegan, allergic to nuts, don't eat pork and so on. Everyone is different so you should do your own research. Here is my list of resources that I used in the article if you want to read further.</p>

      <ol>
         <li>
            <p><cite><a 
            href="https://www.healthline.com/health/beauty-skin-care/vitamin-e-for-hair#benefits" 
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none" }}>What can vitamin E do for your hair?</a>
            </cite> by Healthline</p>
         </li>
         <li>
            <p><cite><a 
            href="https://ods.od.nih.gov/factsheets/Zinc-HealthProfessional/" 
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none" }}>Zinc - Fact Sheet for Health Professionals</a>
            </cite> by National Institues of Health</p>
         </li>
         <li>
            <p><cite><a 
            href="https://www.healthline.com/health/zinc-deficiency#diagnosis" 
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none" }}>Diagnosing zinc deficiency</a>
            </cite> by Healthline</p>
         </li>
         <li>
            <p><cite><a 
            href="https://www.healthline.com/nutrition/best-vitamins-hair-growth" 
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none" }}>The 5 Best Vitamins for Hair Growth (+3 Other Nutrients)</a>
            </cite> by Healthline</p>
         </li>
         <li>
            <p><cite><a 
            href="https://www.hollandandbarrett.com/the-health-hub/natural-beauty/haircare/which-vitamins-are-good-for-hair/" 
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none" }}>Which vitamins are good for hair?</a>
            </cite> by Holland & Barrett</p>
         </li>
         <li>
            <p><cite><a 
            href="https://www.webmd.com/connect-to-care/hair-loss/best-vitamins-for-hair-loss-prevention" 
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none" }}>The 5 Best Vitamins for Hair Loss Prevention, Based on Research</a>
            </cite> by WebMD</p>
         </li>
         <li>
            <p><cite><a 
            href="https://www.webmd.com/connect-to-care/hair-loss/best-vitamins-for-hair-loss-prevention" 
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none" }}>The 5 Best Vitamins for Hair Loss Prevention, Based on Research</a>
            </cite> by WebMD</p>
         </li>
         <li>
            <p><cite><a 
            href="https://www.superdrug.com/blog/wellbeing/hair-vitamins" 
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none" }}>Do I Need Hair Vitamins?</a>
            </cite> by Superdrug</p>
         </li>
      </ol>


    <FurtherReading
      content={
        <div>
        <p>
            <Link to="/afro-hair-products/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
             The Best Afro Hair Products
            </Link>{" "}
            - The complete list of products you need for every step in your hair care routine...
          </p>  
        <p>
            <Link to="/14-tips-on-how-to-grow-afro-hair/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
            14 tips on how to grow afro hair
            </Link>{" "}
            - Growing afro hair can be one of the most annoying things in the world, the good news is that growing natural doesn't have to be hard. Read our 14 tips to make your life easier here...
          </p>   
          <p>
            <Link to="/cantu-review/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
            Cantu Beauty Review
            </Link>{" "}
            - Everything you need to know about Cantu Beauty the brand and their hair products...
          </p>                 
        </div>
      }
    />

    <Link to="/" style={{ textDecoration: "none" }}>
      Go back to the homepage
    </Link>
  </Layout>
)

export default SevenVitamins
